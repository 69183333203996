<template>
  <div>
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <b-card v-if="currpage == 'list'">
            <div class="p-0" v-if="vendorlist">
              <div class="w-100 text-right pr-6" style="margin-bottom:10px">
                <el-button type="primary" class="big-button" icon="el-icon-circle-plus" size="large" @click="add_vendor" v-acl:VendorEdit.enable.show>
                  {{ $t("Add Vendor") }}
                </el-button>
                <el-checkbox style="margin-left: 30px" v-model="includesInactiveVendors" :label="$t('Includes inactive')" size="large" />
              </div>
              <el-table
                class="table-responsive table"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="vendorlist"
                :cell-style="{ padding: '0', height: '45px' }"
                :row-class-name="vendorRowClassName"
                @row-click="edit_vendor"
              >
                <el-table-column label="Name" class="p-0" min-width="15">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Name") + "1" }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_CompanyName"
                      @keyup.native="get_vendorMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.CompanyName
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Name" class="p-0" min-width="15">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Name") + "2" }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_CompanyName2"
                      @keyup.native="get_vendorMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.OtherName
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Contact" min-width="10">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Contact") }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_Contact"
                      @keyup.native="get_vendorMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.ContactLastName1
                        ? scope.row.ContactFirstName1 + " " + scope.row.ContactLastName1
                        : scope.row.ContactFirstName1
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="TelExt" min-width="10">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Tel") }}({{ $t("Ext") }})
                    </div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_TEL"
                      @keyup.native="get_vendorMA_list_delay"
                    ></b-input>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.ContactExt1
                        ? scope.row.TEL + " (" + scope.row.ContactExt1 + ")"
                        : scope.row.TEL
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Cel" prop="ContactCell1" min-width="10">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Cel") }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_ContactCell1"
                      @keyup.native="get_vendorMA_list_delay"
                    ></b-input>
                  </template>
                </el-table-column>
                <el-table-column label="Email" prop="ContactEMail1" min-width="15">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Email") }}</div>
                    <b-input
                      class="p-0 table-header-input"
                      size="mini"
                      v-model="cur_ContactEMail1"
                      @keyup.native="get_vendorMA_list_delay"
                    ></b-input>
                  </template>
                </el-table-column>
                <el-table-column label="Type" prop="CompanyType" min-width="10">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">{{ $t("Type") }}</div>
                    <el-select
                      class="p-0 m-0"
                      size="mini"
                      v-model="show_CompanyType"
                      placeholder=""
                    >
                      <el-option v-for="item in company_typelist_withEmpty"
                                :key="item.IDn"
                                :label="item.CompanyType"
                                :value="item.IDn"
                                @click.native="get_vendorMA_list_delay">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-slot="{ row }">
                    <div class="pl-4">{{ getCompanyType(row.CompanyTypeIDn) }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="Sales" prop="SalesName" min-width="10">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">{{ $t("Sales0") }}</div>
                    <el-select
                      class="p-0 m-0"
                      size="mini"
                      v-model="cur_SalesName"
                      placeholder=""
                    >
                      <el-option v-for="item in saleslist"
                                :key="item.IDn"
                                :label="item.UserName"
                                :value="item.IDn"
                                @click.native="get_vendorMA_list_delay">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-slot="{ row }">
                    <div class="pl-4">{{ row.SalesName }}</div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Status" prop="Status">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                    <el-select
                      class="p-0 m-0"
                      size="mini"
                      v-model="cur_Status"
                      placeholder=""
                    >
                      <el-option v-for="item in status_options"
                                :key="item.value"
                                :label="$t(item.label)"
                                :value="item.value"
                                @click.native="get_vendorMA_list_delay">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-slot="{ row }">
                    {{
                      row.Status == 1 ? $t("Active") : $t("Inactive")
                    }}
                  </template>
                </el-table-column> -->
              </el-table>
              <el-row style="margin-top: 20px">
          <el-col :span="20">
            <div>
              <b-pagination
                v-model="current_page"
                :total-rows="vendor_total"
                :per-page="per_page"
              ></b-pagination>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <!-- <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $t("Max Rows per page") }} {{ this.per_page
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="page_option in per_page_option"
                    :key="page_option"
                    :command="page_option"
                    >{{ page_option }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
              <span>
                {{ $t("Max Rows per page") }}
              </span>
              <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  {{ per_page }}
                </template>
                <b-dropdown-item 
                  v-for="page_option in per_page_option"
                  :key="page_option"
                  :command="page_option"
                  @click="handleCommand">
                  {{ page_option }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </el-col>
        </el-row>
            </div>
            <div class="p-3" v-else>
              {{ $t("No Records") }}
            </div>
          </b-card>
          <b-card v-if="currpage == 'edit'">
            <el-row :gutter="10" style="">
              <el-col :span="12">
                <strong style="margin-right: 10px">{{ $t('Customer ID') + ":"}}</strong>
                <span>{{cur_vendor.Code ? cur_vendor.Code : "To Be Assigned"}}</span>
              </el-col>
              <el-col :span="12" class="text-right">
                <strong style="margin-right: 40px">{{ $t('Credit Alert') + ":"}}</strong>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="cur_vendor.Warning"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  style="padding-top: 6px"
                  >
                </b-form-checkbox>
                <strong style="margin-right: 40px">{{ $t('Status') + ":"}}</strong>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="cur_vendor.Status"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  style="padding-top: 6px"
                > 
                </b-form-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="16">
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="'*' + $t('Name') + ' 1'">
                      <b-input
                        :placeholder="$t('Name') + ' 1'"
                        class="form-control"
                        v-model="cur_vendor.CompanyName"
                        @input="validateInputField"
                        autocomplete="off"
                        :state="Name1_State"
                        aria-describedby="input-live-help input-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Enter vendor's name
                      </b-form-invalid-feedback>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="'*' + $t('Tel')">
                      <b-input
                        placeholder="Tel"
                        class="form-control"
                        v-model="cur_vendor.TEL"
                        @input="validateInputField"
                        autocomplete="off"
                        :state="TEL_State"
                        aria-describedby="input-live-help input-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Enter valid phone number
                      </b-form-invalid-feedback>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Type')">
                      <InputAutocomplete2
                        :items="company_typelist"
                        :initval="Number(cur_vendor.CompanyTypeIDn)"
                        :placeholder="$t('Type')"
                        :key_field="'IDn'"
                        :value_field="'CompanyType'"
                        @input="setinput_companytype"
                      >
                      </InputAutocomplete2>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('GST Number')">
                      <b-input
                        :placeholder="$t('GST Number')"
                        class="form-control"
                        v-model="cur_vendor.GSTNo"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="$t('Name') + ' 2'">
                      <b-input
                        :placeholder="$t('Name') + ' 2'"
                        class="form-control"
                        v-model="cur_vendor.OtherName"
                        autocomplete="off"
                        aria-describedby="input-live-help input-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Enter vendor's alternative name
                      </b-form-invalid-feedback>
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Fax')">
                      <b-input
                        :placeholder="$t('Fax')"
                        class="form-control"
                        v-model="cur_vendor.FAX"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Website')">
                      <b-input
                        :placeholder="$t('Website')"
                        class="form-control"
                        v-model="cur_vendor.WebSite"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('PST Number')">
                      <b-input
                        :placeholder="$t('PST Number')"
                        class="form-control"
                        v-model="cur_vendor.PSTNo"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <base-input :label="$t('Remarks')">
                  <b-form-textarea
                    :placeholder="$t('Remarks')"
                    class="form-control"
                    v-model="cur_vendor.Remarks"
                    autocomplete="off"
                    rows="5"
                    resize="none"
                  />
                </base-input>
              </el-col>
            </el-row>
          </b-card>

          <!-- <b-card v-if="currpage == 'edit'">
            <strong style="margin-right: 40px">{{ '*' + $t('Address') + ":"}}</strong>
            <div :style="address_State ? 'border: none' : 'border: 1px solid #fb6340; border-radius: 0.375rem' ">
              <AddressInput
                address_type="vendor"
                :token="token"
                :user_id="user_id"
                :ReferenceIDn="Number(cur_vendor.IDn)"
                @added_address_IDn="add_to_address_added"
                @deleted_address_IDn="remove_from_address_added"
                @cur_address_list="set_cur_vendor_address_array"
                @temp_new_address="add_to_cur_vendor_address_array"
                @temp_delete_address="remove_from_cur_vendor_address_array"
                @temp_billing_address="set_billing_cur_vendor_address_array"
                @temp_shipping_address="set_shipping_cur_vendor_address_array"
              >
              </AddressInput>
              <span v-if="!address_State" style="padding: 0.3rem; width: 100%; margin-top: 0.25rem; font-size: 80%; color:#fb6340"> {{ "Please provide an address" }} </span>
            </div>
          </b-card> -->

          <b-card v-if="currpage == 'edit'">
            <div :style="address_State ? 'border: none' : 'border: 1px solid #fb6340; border-radius: 0.375rem' ">
              <el-table
                class="table-responsive table"
                style="width: 100%"
                :header-cell-style="{background: '#eef1f6'}"
                :cell-style="{padding: '10px 0', background: '#fff'}"
                :data="[cur_vendor]"
              >
                <el-table-column label="Address" prop="Address" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Address") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <b-input
                      :placeholder="$t('Address')"
                      class="form-control"
                      v-model="row.Address1"
                      @input="validateInputField"
                      style="height: 40px"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Country" prop="Country" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Country") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <el-select
                      class="el-dropdown2"
                      v-model="row.Country"
                      style="width: 100%;"
                      @change="validateInputField"
                    >
                      <el-option v-for="item in countrylist"
                                :key="item.IDn"
                                :value="item.IDn"
                                :label="item.Country">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="Province" prop="Province" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Province") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <el-select
                      class="el-dropdown2"
                      v-model="row.Province"
                      style="width: 100%;"
                      @change="validateInputField"
                    >
                      <el-option v-for="item in cur_provincelist"
                                :key="item.IDn"
                                :value="item.IDn"
                                :label="item.Province">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="City" prop="City" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("City") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <!-- <InputAutocomplete
                      :placeholder="$t('City')"
                      :key="row.City"
                      :items="citylist"
                      :initval="row.City"
                      v-model="row.City"
                      @input="setinput_city"
                    ></InputAutocomplete> -->
                    <el-autocomplete
                      v-model="row.City"
                      :fetch-suggestions="querySearch_city"
                      class="inline-input"
                      value-key="Name"
                      :placeholder="$t('City')"
                      style="width: 100%"
                      @select="setinput_city"
                      @input="validateInputField"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Postal" prop="Address" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Postal Code") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <b-input
                      :placeholder="$t('Postal Code')"
                      class="form-control"
                      v-model="row.Postal"
                      style="height: 40px"
                      @input="validateInputField"
                    />
                  </template>
                </el-table-column>
              </el-table>
              <span v-if="!address_State" style="padding: 0.3rem; width: 100%; margin-top: 0.25rem; font-size: 80%; color:#fb6340"> 
                {{ "Please provide an address" }} 
              </span>
            </div>
          </b-card>

          <!-- <b-card v-if="currpage == 'edit'">
            <el-row :gutter="10" style="width: 300px; margin-bottom: 20px">
              <el-col :span="24">
                <strong style="margin-right: 40px">{{ $t('Credit Alert') + ":"}}</strong>
                <b-form-checkbox
                  class="form-checkbox"
                  v-model="cur_vendor.Warning"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  style="float: right"
                  >{{ cur_vendor.Warning==0 ? $t('Disable') : $t('Enable')}}
                </b-form-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="6">
                <base-input :label="$t('PST Number')">
                  <b-input
                    :placeholder="$t('PST Number')"
                    class="form-control"
                    v-model="cur_vendor.PSTNo"
                    autocomplete="off"
                  />
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Classification')">
                  <InputAutocomplete2
                    :items="company_classlist"
                    :initval="Number(cur_vendor.CompanyClassIDn)"
                    :placeholder="$t('Classification')"
                    :key_field="'IDn'"
                    :value_field="'Classification'"
                    @input="setinput_companyclass"
                  >
                  </InputAutocomplete2>
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Price Level')">
                  <select class="form-control" v-model="cur_vendor.PriceGroup">
                    <option value="" disabled selected>
                      -- {{ $t("Price Level") }} --
                    </option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                  </select>
                  <el-select
                    class="el-dropdown"
                    v-model="cur_vendor.PriceList"
                    placeholder=" "
                  >
                    <el-option v-for="item in PriceList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                @click.native="setinput_pricelist(item)">
                      </el-option>
                  </el-select>
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Payment Term')">
                  <InputAutocomplete2
                    :items="pay_periodlist"
                    :initval="Number(cur_vendor.PaymentTermIDn)"
                    :placeholder="$t('Payment Term')"
                    :key_field="'IDn'"
                    :value_field="'PayPeriod'"
                    @input="setinput_payperiod"
                  >
                  </InputAutocomplete2>
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Credit Limit')">
                  <InputAutocomplete2
                    :items="credit_termlist"
                    :initval="Number(cur_vendor.CreditTermIDn)"
                    :placeholder="$t('Credit Limit')"
                    :key_field="'IDn'"
                    :value_field="'CreditTerm'"
                    @input="setinput_creditterm"
                  >
                  </InputAutocomplete2>
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Sales0')">
                  <select class="form-control" v-model="cur_vendor.UserIDn">
                    <option :value="0" disabled selected>
                      -- {{ $t("Sales0") }} --
                    </option>
                    <option
                      v-for="(sales, idx) in saleslist"
                      :key="idx"
                      :value="sales.IDn"
                    >
                      {{ sales.UserName }}
                    </option>
                  </select>
                  <el-select
                    class="el-dropdown"
                    v-model="cur_vendor.UserIDn"
                  >
                    <el-option v-for="item in saleslist"
                              :key="item.IDn"
                              :value="item.IDn"
                              :label="item.UserName">
                    </el-option>
                  </el-select>
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Product Classification')">
                  <InputAutocomplete2
                    :items="product_classlist"
                    :initval="Number(cur_vendor.ProductClassIDn)"
                    :placeholder="$t('Product Classification')"
                    :key_field="'IDn'"
                    :value_field="'Classification'"
                    @input="setinput_productclass"
                  >
                  </InputAutocomplete2>
                </base-input>
              </el-col>
              <el-col :span="6">
                <base-input :label="$t('Product Category')">
                  <InputAutocomplete2
                    :items="item_groupinglist"
                    :initval="Number(cur_vendor.ItemGroupingIDn)"
                    :placeholder="$t('Product Category')"
                    :key_field="'IDn'"
                    :value_field="'ItemGrouping'"
                    @input="setinput_itemgrouping"
                  >
                  </InputAutocomplete2>
                </base-input>
              </el-col>
            </el-row>
          </b-card> -->

          <el-collapse v-if="currpage == 'edit'" accordion>
            <el-collapse-item
              class="collapseheader"
              :title="$t('Contact')"
              name="1"
            >
              <fieldset>
                <legend style="margin-left: 15px; padding-top: 10px;">{{ $t("Contact") + " 1" }}</legend>
                <el-row :gutter="10" style="margin: 0 10px">
                  <el-col :span="4">
                    <base-input :label="$t('First Name')">
                      <b-input
                        :placeholder="$t('First Name')"
                        class="form-control"
                        v-model="cur_vendor.ContactFirstName1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Last Name')">
                      <b-input
                        :placeholder="$t('Last Name')"
                        class="form-control"
                        v-model="cur_vendor.ContactLastName1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Cel')">
                      <b-input
                        :placeholder="$t('Cel')"
                        class="form-control"
                        v-model="cur_vendor.ContactCell1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Ext')">
                      <b-input
                        :placeholder="$t('Ext')"
                        class="form-control"
                        v-model="cur_vendor.ContactExt1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Email')">
                      <b-input
                        :placeholder="$t('Email')"
                        class="form-control"
                        v-model="cur_vendor.ContactEMail1"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Position')">
                      <InputAutocomplete2
                        :items="positionlist"
                        :initval="Number(cur_vendor.ContactPositionIDn1)"
                        :placeholder="$t('Position')"
                        :key_field="'IDn'"
                        :value_field="'PositionName'"
                        @input="setinput_contactposition1"
                      >
                      </InputAutocomplete2>
                    </base-input>
                  </el-col>
                </el-row>
              </fieldset>

              <fieldset>
                <legend style="margin-left: 15px; padding-top: 10px">{{ $t("Contact") + " 2" }}</legend>
                <el-row :gutter="10" style="margin: 0 10px; padding-bottom: 10px">
                  <el-col :span="4">
                    <base-input :label="$t('First Name')">
                      <b-input
                        :placeholder="$t('First Name')"
                        class="form-control"
                        v-model="cur_vendor.ContactFirstName2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Last Name')">
                      <b-input
                        :placeholder="$t('Last Name')"
                        class="form-control"
                        v-model="cur_vendor.ContactLastName2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Cel')">
                      <b-input
                        :placeholder="$t('Cel')"
                        class="form-control"
                        v-model="cur_vendor.ContactCell2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Ext')">
                      <b-input
                        :placeholder="$t('Ext')"
                        class="form-control"
                        v-model="cur_vendor.ContactExt2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Email')">
                      <b-input
                        :placeholder="$t('Email')"
                        class="form-control"
                        v-model="cur_vendor.ContactEMail2"
                        autocomplete="off"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="4">
                    <base-input :label="$t('Position')">
                      <InputAutocomplete2
                        :items="positionlist"
                        :initval="Number(cur_vendor.ContactPositionIDn2)"
                        :placeholder="$t('Position')"
                        :key_field="'IDn'"
                        :value_field="'PositionName'"
                        @input="setinput_contactposition2"
                      >
                      </InputAutocomplete2>
                    </base-input>
                  </el-col>
                </el-row>
              </fieldset>
            </el-collapse-item>

            <!-- <el-collapse-item
              class="collapseheader"
              :title="$t('Carrier')"
              name="2"
            >
              <el-row :gutter="10" style="padding: 10px">
                <el-col :span="6">
                  <base-input :label="$t('Carrier')">
                    <el-autocomplete
                      class="inline-input el-dropdown"
                      id="carrier_name_textbox"
                      v-model="cur_carrier.CarrierName"
                      value-key="CarrierName"
                      :fetch-suggestions="querySearch"
                      :placeholder="$t('Carrier')"
                      @select="selectCarrier"
                    ></el-autocomplete>
                  </base-input>
                </el-col>
                <el-col :span="6">
                  <base-input :label="$t('Tel')">
                    <b-input
                      :placeholder="$t('Tel')"
                      class="form-control"
                      v-model="cur_carrier.CarrierTEL"
                      autocomplete="off"
                    />
                  </base-input>
                </el-col>
                <el-col :span="6">
                  <base-input :label="$t('Fax')">
                    <b-input
                      :placeholder="$t('Fax')"
                      class="form-control"
                      v-model="cur_carrier.CarrierFAX"
                      autocomplete="off"
                    />
                  </base-input>
                </el-col>
                <el-col :span="6">
                  <base-input :label="$t('Account Number')">
                    <b-input
                      :placeholder="$t('Account Number')"
                      class="form-control"
                      v-model="cur_carrier.CarrierAccountNo"
                      autocomplete="off"
                    />
                  </base-input>
                </el-col>
              </el-row>
            </el-collapse-item> -->

            <el-collapse-item
              class="collapseheader"
              :title="$t('Extra')"
              name="2"
            >
              <el-row :gutter="10" style="padding: 10px">
                <el-col :span="4">
                  <base-input :label="$t('Payment Term')">
                    <!-- <InputAutocomplete2
                      :items="pay_periodlist"
                      :initval="Number(cur_vendor.PaymentTermIDn)"
                      :placeholder="$t('Payment Term')"
                      :key_field="'IDn'"
                      :value_field="'PayPeriod'"
                      @input="setinput_payperiod"
                    >
                    </InputAutocomplete2> -->
                    <el-autocomplete
                      v-model="cur_PayPeriod"
                      :fetch-suggestions="querySearch_paymentTerm"
                      class="inline-input"
                      value-key="PayPeriod"
                      :placeholder="$t('Payment Term')"
                      style="width: 100%"
                      @select="setinput_payperiod"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Credit Limit')">
                    <!-- <InputAutocomplete2
                      :items="credit_termlist"
                      :initval="Number(cur_vendor.CreditTermIDn)"
                      :placeholder="$t('Credit Limit')"
                      :key_field="'IDn'"
                      :value_field="'CreditTerm'"
                      @input="setinput_creditterm"
                    >
                    </InputAutocomplete2> -->
                    <el-autocomplete
                      v-model="cur_CreditTerm"
                      :fetch-suggestions="querySearch_creditTerm"
                      class="inline-input"
                      value-key="CreditTerm"
                      :placeholder="$t('Credit Term')"
                      style="width: 100%"
                      @select="setinput_creditterm"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Classification')">
                    <!-- <InputAutocomplete2
                      :items="company_classlist"
                      :initval="Number(cur_vendor.CompanyClassIDn)"
                      :placeholder="$t('Classification')"
                      :key_field="'IDn'"
                      :value_field="'Classification'"
                      @input="setinput_companyclass"
                    >
                    </InputAutocomplete2> -->
                    <el-autocomplete
                      v-model="cur_CompanyClass"
                      :fetch-suggestions="querySearch_classification"
                      class="inline-input"
                      value-key="Classification"
                      :placeholder="$t('Classification')"
                      style="width: 100%"
                      @select="setinput_companyclass"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Product Classification')">
                    <!-- <InputAutocomplete2
                      :items="product_classlist"
                      :initval="Number(cur_vendor.ProductClassIDn)"
                      :placeholder="$t('Product Classification')"
                      :key_field="'IDn'"
                      :value_field="'Classification'"
                      @input="setinput_productclass"
                    >
                    </InputAutocomplete2> -->
                    <el-autocomplete
                      v-model="cur_ProductClass"
                      :fetch-suggestions="querySearch_productClassification"
                      class="inline-input"
                      value-key="Classification"
                      :placeholder="$t('Product Classification')"
                      style="width: 100%"
                      @select="setinput_productclass"
                    />
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input :label="$t('Product Category')">
                    <!-- <InputAutocomplete2
                      :items="item_groupinglist"
                      :initval="Number(cur_vendor.ItemGroupingIDn)"
                      :placeholder="$t('Product Category')"
                      :key_field="'IDn'"
                      :value_field="'ItemGrouping'"
                      @input="setinput_itemgrouping"
                    >
                    </InputAutocomplete2> -->
                    <el-autocomplete
                      v-model="cur_ItemGrouping"
                      :fetch-suggestions="querySearch_productCategory"
                      class="inline-input"
                      value-key="ItemGrouping"
                      :placeholder="$t('Product Category')"
                      style="width: 100%"
                      @select="setinput_itemgrouping"
                    />
                  </base-input>
                </el-col>
              </el-row>
            </el-collapse-item>

            <el-collapse-item
              class="collapseheader"
              :title="$t('Files')"
              name="3"
              v-acl:FileUtilities.enable.show
            >
              <div>
                <el-row :gutter="10" style="padding: 10px">
                  <el-col :span="12">
                    <b-form-file
                      v-model="userfile"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <!-- :state="Boolean(userfile)" -->
                  </el-col>
                  <el-col :span="12" >
                    <el-button type="primary" @click="file_upload">
                      {{ $t("Upload File") }}
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <el-table
                stripe
                class="table-responsive table "
                style="padding: 10px"
                :header-cell-style="{background: '#eef1f6'}"
                :data="cur_file_list"
              >
                <el-table-column
                  :label="$t('Name')"
                  prop="OrigName"
                  class="p-0"
                  min-width="100px"
                >
                </el-table-column>
                <el-table-column :label="$t('Date Modified')">
                  <template v-slot="{ row }">
                    {{ row.CreateDate.substring(0, 19) }}
                  </template>
                </el-table-column>
                <el-table-column label="">
                  <template v-slot="{ row }">
                    <el-tooltip
                      effect="dark"
                      content="Delete"
                      placement="top"
                      :openDelay="1000"
                    >
                      <el-button
                        type="danger"
                        class="button"
                        icon="el-icon-delete"
                        @click="file_delete(row, $event)"
                      >
                      </el-button>
                    </el-tooltip>

                    <el-tooltip
                      effect="dark"
                      content="Download"
                      placement="top"
                      :openDelay="1000"
                    >
                      <el-button
                        class="button"
                        icon="el-icon-download"
                        @click="file_download(row, $event)"
                      >
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </b-col>
      </b-row>
      <b-row class="mt-4" style="padding-top: 30px">
        <b-col v-if="currpage == 'edit'">
          <el-row :gutter="50">
            <el-col :span="12">
              <el-button type="primary" @click="cancel_edit" style="float: right">
                {{ $t("Back") }}
              </el-button>
            </el-col>
            <el-col :span="12" >
              <el-button type="primary" @click="validateForm">
                {{ $t("Submit Vendor") }}
              </el-button>
            </el-col>
          </el-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Select,
  Option,
  OptionGroup,
  Autocomplete,
  Icon,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
} from "element-ui";

// import InputAutocomplete from "../InputAutocomplete";
import InputAutocomplete2 from "../InputAutocomplete2";
import AddressInput from "../AddressInput";
// import flatPicker from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";
import InputAutocomplete from "../InputAutocomplete";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Autocomplete.name]: Autocomplete,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    // flatPicker,
    // InputAutocomplete,
    InputAutocomplete2,
    // AddressInput,
    // InputAutocomplete,
  },
  data() {
    return {
      includesInactiveVendors: false,
      Name1_State: true,
      TEL_State: true,
      address_State: true,
      status_options: [{
          value: "",
          label: " "
        },{
          value: "1",
          label: "Active"
        }, {
          value: "0",
          label: "Inactive"
      }],
      cur_CompanyName: "",
      cur_CompanyName2: "",
      cur_Contact: "",
      cur_TEL: "",
      cur_ContactCell1: "",
      cur_ContactEMail1: "",
      show_CompanyType: "",
      cur_SalesName: "",
      cur_Status: 'Active',
      cur_provincelist: [],
      address_added: [],
      cur_vendor_address_array: [],

      currpage: "list",
      cur_opt: null,
      cur_vendor: {},
      new_vendor: {
        IDn: 0,
        Code: "",
        CompanyName: "",
        OtherName: "",
        Status: 1,
        WebSite: "",
        TEL: "",
        FAX: "",
        CompanyTypeIDn: 0,
        PaymentTermIDn: 1,
        CreditTermIDn: 0,
        CompanyClassIDn: 0,
        ProductClassIDn: 0,
        ItemGroupingIDn: 0,
        PriceList: "",
        CarrierIDn: 0,
        ContactFirstName1: "",
        ContactLastName1: "",
        ContactPositionIDn1: 0,
        ContactCell1: "",
        ContactExt1: "",
        ContactEMail1: "",
        ContactFirstName2: "",
        ContactLastName2: "",
        ContactPositionIDn2: 0,
        ContactCell2: "",
        ContactExt2: "",
        ContactEMail2: "",
        CreateDateTime: "",
        UpdateDateTime: "",
        Remarks: "",
        Warning: 0,
        PSTNo: "",
        GSTNo: "",
        CreditAmount: "",
        SupportData: "",
        UserIDn: 0,
        Address1: "",
        City: "",
        Province: "",
        Country: "",
        Postal: "",
      },
      cur_CompanyType: "",
      cur_PayPeriod: "",
      cur_PriceList: "",
      cur_CreditTerm: "",
      cur_CompanyClass: "",
      cur_ProductClass: "",
      cur_ItemGrouping: "",
      cur_ContactPosition1: "",
      cur_ContactPosition2: "",

      carrier_list: [],
      carrier_name_list: [],
      carrier_account_no_list: [],
      cur_carrier_name: "",
      cur_carrier: {
        IDn: 0,
        CarrierName: "",
        CarrierTEL: "",
        CarrierFAX: "",
        CarrierAccountNo: "",
      },
      new_carrier: {
        IDn: 0,
        CarrierName: "",
        CarrierTEL: "",
        CarrierFAX: "",
        CarrierAccountNo: "",
        Order: 999,
      },
      cur_address_list: [],
      cur_address: {},
      new_address: {
        IDn: 0,
        Type: "",
        ReferenceIDn: 0,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: 0,
        AddressProvince: "",
        CountryIDn: 0,
        AddressCountry: "",
        ZIP: "",
        Remarks: "",
        // BillingFlag: '',
        // ShippingFlag: '',
      },

      vendorlist: [],
      // tax_typelist: [],
      company_typelist: [],
      company_typelist_withEmpty: [],
      positionlist: [],
      company_classlist: [],
      product_classlist: [],
      item_groupinglist: [],
      credit_termlist: [],
      pay_periodlist: [],
      saleslist: [],
      countrylist: [],
      provincelist: [],
      citylist: [],
      cur_file_list: [],

      userfile: null,

      delay_timer: null,

      current_page: 1,
      per_page: 20,
      vendor_total: 0,

      token: "",
      user_id: 0,

      test: "",
      per_page_option: [5, 10, 20],
      PriceList: [{
          value: "",
          label: " "
        },{
          value: "A",
          label: "A"
        },{
          value: "B",
          label: "B"
        },{
          value: "C",
          label: "C"
        },{
          value: "D",
          label: "D"
        },{
          value: "E",
          label: "E"
        },{
          value: "F",
          label: "F"
        },{
          value: "G",
          label: "G"
        },{
          value: "H",
          label: "H"
        },{
          value: "I",
          label: "I"
        },{
          value: "J",
          label: "J"
      }],
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_vendorMA_list();
    },
    includesInactiveVendors: function(newval, oldval) {
      newval ? this.cur_Status="" : this.cur_Status = "1";
      this.get_vendorMA_list();
    },

    'cur_vendor.Country': function(newval, oldval){
      this.setcur_provincelist(newval, oldval);
    },
    deep: true,

  },
  methods: {
    hasUtilityAuth,
    selectCarrier(item) {
      this.cur_carrier.CarrierName = item.CarrierName;
      this.cur_carrier.CarrierTEL = item.CarrierTEL;
      this.cur_carrier.CarrierFAX = item.CarrierFAX;
      this.cur_carrier.CarrierAccountNo = item.CarrierAccountNo;
      this.cur_carrier.IDn = parseInt(item.IDn);
    },
    querySearch(queryString, cb) {
      var links = Object.values(this.carrier_list);
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.CarrierName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },

    querySearch_city(queryString, cb) {
      var links = [];
      for(let i=0; i<this.citylist.length; i++) {
        links.push({
          Key: i,
          Name: this.citylist[i]
        })
      }
      var results = queryString ? links.filter(this.createFilter_city(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_city(queryString) {
      return (link) => {
        return (link.Name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_paymentTerm(queryString, cb) {
      var links = Object.values(this.pay_periodlist);
      var results = queryString ? links.filter(this.createFilter_paymentTerm(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_paymentTerm(queryString) {
      return (link) => {
        return (link.PayPeriod.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_creditTerm(queryString, cb) {
      var links = Object.values(this.credit_termlist);
      var results = queryString ? links.filter(this.createFilter_creditTerm(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_creditTerm(queryString) {
      return (link) => {
        return (link.CreditTerm.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_classification(queryString, cb) {
      var links = Object.values(this.company_classlist);
      var results = queryString ? links.filter(this.createFilter_classification(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_classification(queryString) {
      return (link) => {
        return (link.Classification.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_productClassification(queryString, cb) {
      var links = Object.values(this.product_classlist);
      var results = queryString ? links.filter(this.createFilter_productClassification(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_productClassification(queryString) {
      return (link) => {
        return (link.Classification.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch_productCategory(queryString, cb) {
      var links = Object.values(this.item_groupinglist);
      var results = queryString ? links.filter(this.createFilter_productCategory(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter_productCategory(queryString) {
      return (link) => {
        return (link.ItemGrouping.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    vendorRowClassName({row, rowIndex}) {
      if (row.Status != 1) {
        return 'inactive-vendor';
      }
      return '';
    },
    resetCarrier(){
      this.cur_carrier = {
        IDn: 0,
        CarrierName: "",
        CarrierTEL: "",
        CarrierFAX: "",
        CarrierAccountNo: "",
      };
    },
    handleCommand(selected) {
      // console.log("click on item " + selected);
      this.per_page = Number(selected.target.innerText);
      // console.log(this.per_page);
      return this.get_vendorMA_list();
    },
    extractURL(url) {
      if (axios.defaults.baseURL === undefined) {
        return url;
      } else {
        return axios.defaults.baseURL + "/Api" + url;
      }
    },
    getCompanyType(idn) {
      for(let i=0; i<this.company_typelist.length; i++) {
        if(idn == parseInt(this.company_typelist[i].IDn)) {
          return this.company_typelist[i].CompanyType;
        }
      }
      return "";
    },
    setinput_companytype(value) {
      this.cur_vendor.CompanyTypeIDn = value.IDn;
      this.cur_CompanyType = value.CompanyType;
    },
    setinput_payperiod(value) {
      this.cur_vendor.PaymentTermIDn = value.IDn;
      this.cur_PayPeriod = value.PayPeriod;
    },
    setinput_pricelist(value){
      this.cur_PriceList = value.value;
    },
    setinput_creditterm(value) {
      this.cur_vendor.CreditTermIDn = value.IDn;
      this.cur_CreditTerm = value.CreditTerm;
    },
    setinput_companyclass(value) {
      this.cur_vendor.CompanyClassIDn = value.IDn;
      this.cur_CompanyClass = value.Classification;
    },
    setinput_productclass(value) {
      this.cur_vendor.ProductClassIDn = value.IDn;
      this.cur_ProductClass = value.Classification;
    },
    setinput_itemgrouping(value) {
      this.cur_vendor.ItemGroupingIDn = value.IDn;
      this.cur_ItemGrouping = value.ItemGrouping;
    },
    setinput_contactposition1(value) {
      this.cur_vendor.ContactPositionIDn1 = value.IDn;
      this.cur_ContactPosition1 = value.PositionName;
    },
    setinput_contactposition2(value) {
      this.cur_vendor.ContactPositionIDn2 = value.IDn;
      this.cur_ContactPosition2 = value.PositionName;
    },
    setinput_city(city) {
      this.cur_address.AddressCity = city;
    },
    setcur_provincelist(countryIDn, oldval) {
      // Reset Province 
      if(oldval){
        this.cur_vendor.Province = ""; 
      }
      // Get Provinces of provided country
      this.cur_provincelist = [];
      for (var idx in this.provincelist) {
        if (this.provincelist[idx].CountryIDn == countryIDn) {
          this.cur_provincelist.push(this.provincelist[idx]);
        }
      }
    },
    address_combination(address) {
      let rt = "";
      rt += address.Street2
        ? address.Street1 + " " + address.Street2
        : address.Street1;
      rt += address.ZIP ? (rt ? ", " : "") + address.ZIP : "";
      rt += address.AddressCity ? (rt ? ", " : "") + address.AddressCity : "";
      rt += address.AddressProvince
        ? (rt ? ", " : "") + address.AddressProvince
        : "";
      rt += address.AddressCountry
        ? (rt ? ", " : "") + address.AddressCountry
        : "";
      return rt;
    },
    async add_vendor() {
      this.currpage = "edit";
      this.cur_opt = "add";

      this.cur_vendor = JSON.parse(JSON.stringify(this.new_vendor));
      this.cur_carrier_name = "";
      this.resetCarrier();
      this.cur_vendor.UserIDn = parseInt(this.user_id);
      this.address_added = [];
      this.cur_vendor_address_array = [];
      this.cur_PayPeriod = "";
      this.cur_CreditTerm = "";
      this.cur_CompanyClass = "";
      this.cur_ProductClass = "";
      this.cur_ItemGrouping = "";
      setTimeout(()=>{
        this.calculate_el_select_width()
      },50); // timer is used to avoid funciton call before left menu is loaded
      this.get_vendor_extras();
      
    },
    async edit_vendor(row, column, event) {
      if (!hasUtilityAuth('VendorEdit')) return;
      this.currpage = "edit";
      this.cur_opt = "edit";

      this.cur_vendor = JSON.parse(JSON.stringify(row));
      this.cur_vendor.UserIDn = parseInt(this.cur_vendor.UserIDn);
      this.cur_PriceList = this.cur_vendor.PriceList;
      this.Name1_State = true;
      this.TEL_State = true;
      this.address_State = true;
      if(row.CarrierIDn == 0){
        this.cur_carrier_name = "";
      } else {
        this.cur_carrier_name = this.cur_carrier.CarrierName;
      }
      if(row.PaymentTermIDn == 0){
        this.cur_PayPeriod = "";
      } else {
        this.cur_PayPeriod = this.getPayPeriod(row.PaymentTermIDn);
      }
      if(row.CreditTermIDn == 0){
        this.cur_CreditTerm = "";
      } else {
        this.cur_CreditTerm = this.getCreditTerm(row.CreditTermIDn);
      }
      if(row.CompanyClassIDn == 0){
        this.cur_CompanyClass = "";
      } else {
        this.cur_CompanyClass = this.getClassification(row.CompanyClassIDn);
      }
      if(row.ProductClassIDn == 0){
        this.cur_ProductClass = "";
      } else {
        this.cur_ProductClass = this.getProductClassification(row.ProductClassIDn);
      }
      if(row.ItemGroupingIDn == 0){
        this.cur_ItemGrouping = "";
      } else {
        this.cur_ItemGrouping = this.getProductCategory(row.ItemGroupingIDn);
      }
      this.address_added = [];
      this.cur_vendor_address_array = [];
      setTimeout(()=>{
        this.calculate_el_select_width()
      },50); // timer is used to avoid funciton call before left menu is loaded
      await this.initAddressInput();
      await this.get_vendor_extras();
    },
    getPayPeriod(idn) {
      for(let i=0; i<this.pay_periodlist.length; i++){
        if(this.pay_periodlist[i].IDn == idn) {
          return this.pay_periodlist[i].PayPeriod;
        }
      }
      return "";
    },
    getCreditTerm(idn) {
      for(let i=0; i<this.credit_termlist.length; i++){
        if(this.credit_termlist[i].IDn == idn) {
          return this.credit_termlist[i].CreditTerm;
        }
      }
      return "";
    },
    getClassification(idn) {
      for(let i=0; i<this.company_classlist.length; i++){
        if(this.company_classlist[i].IDn == idn) {
          return this.company_classlist[i].Classification;
        }
      }
      return "";
    },
    getProductClassification(idn) {
      for(let i=0; i<this.product_classlist.length; i++){
        if(this.product_classlist[i].IDn == idn) {
          return this.product_classlist[i].Classification;
        }
      }
      return "";
    },
    getProductCategory(idn) {
      for(let i=0; i<this.item_groupinglist.length; i++){
        if(this.item_groupinglist[i].IDn == idn) {
          return this.item_groupinglist[i].ItemGrouping;
        }
      }
      return "";
    },
    async add_to_address_added(idn) {
      this.address_added.push(idn);
      this.validateInputField();
    },
    async remove_from_address_added(idn) {
      for (var i = 0; i < this.address_added.length; i++) {
        if (this.address_added[i] == idn) {
          this.address_added.splice(i, 1);
        }
      }
    },
    async set_cur_vendor_address_array(address_array){
      this.cur_vendor_address_array = address_array;
    },
    async add_to_cur_vendor_address_array(address){
      this.cur_vendor_address_array.push(address);
      this.validateInputField();
    },
    async remove_from_cur_vendor_address_array(address) {
      for (var i=0; i<this.cur_vendor_address_array.length; i++) {
        if (this.cur_vendor_address_array[i] == address) {
          this.cur_vendor_address_array.splice(i,1);
          break;
        }
      }
    },
    async set_billing_cur_vendor_address_array(address) {
      for (var i=0; i<this.cur_vendor_address_array.length; i++) {
        if (this.cur_vendor_address_array[i] == address) {
          this.cur_vendor_address_array.BillingFlag = 1;
        } else {
          this.cur_vendor_address_array.BillingFlag = 0;
        }
      }
    },
    async set_shipping_cur_vendor_address_array(address) {
      for (var i=0; i<this.cur_vendor_address_array.length; i++) {
        if (this.cur_vendor_address_array[i] == address) {
          this.cur_vendor_address_array.ShippingFlag = 1;
        } else {
          this.cur_vendor_address_array.ShippingFlag = 0;
        }
      }
    },
    async initAddressInput(){
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ReferenceIDn", this.cur_vendor.IDn);
      bodyFormData.append("address_type", 'vendor');
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/initAddressInput",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_vendor_address_array = rt.data.address_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async get_vendor_extras() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      bodyFormData.append("ReferenceIDn", this.cur_vendor.IDn);
      if (this.cur_vendor.CarrierIDn != 'undefined' && this.cur_vendor.CarrierIDn > 0) {
        bodyFormData.append("CarrierIDn", this.cur_vendor.CarrierIDn);
      }
      // for(var pair of bodyFormData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]); 
      // }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/Vendor/extras",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data.address_list;
              that.cur_file_list = rt.data.file_list;
              if (rt.data.carrier) {
                that.cur_carrier = rt.data.carrier;
              } else {
                for (let key in that.new_carrier) {
                  // console.log(key);
                  that.cur_carrier[key] = that.new_carrier[key];
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    does_carrier_exist(target){
      for (const key in this.carrier_list) {
        if(this.carrier_list[key].CarrierName == target){
          return true;
        }
      }
      return false;
    },
    validateInputField(){
      if(this.cur_vendor.CompanyName != ''){
        this.Name1_State = true;
      }
      if(this.cur_vendor.TEL != ''){
        this.TEL_State = true;
      }
      if (this.cur_vendor.Address1 != '' && 
          this.cur_vendor.City != '' && 
          this.cur_vendor.Province != '' && 
          this.cur_vendor.Country != '' && 
          this.cur_vendor.Postal != '' ) 
      {
        this.address_State = true;
      }
      // if(this.cur_vendor.IDn == "0") {
      //   if(this.cur_vendor_address_array.length > 0){
      //     this.address_State = true;
      //   }
      // } else {
      //   if(this.address_added.length > 0){
      //     this.address_State = true;
      //   }
      // }
    },
    phoneNumberValidation(phoneNumber){
      var regex = /^[\d.\-()]+$/;
      if(phoneNumber.match(regex)){
        return true;
      } else {
        return false;
      }
    },
    validateForm(){
      let allPassed = true;

      if(this.cur_vendor.CompanyName == ''){
        this.Name1_State = false;
        allPassed = false;
      } else {
        this.Name1_State = true;
      }
      if(this.cur_vendor.TEL == ''){
        this.TEL_State = false;
        allPassed = false;
      } else {
        if(!this.phoneNumberValidation(this.cur_vendor.TEL)){
          this.TEL_State = false;
          allPassed = false;
        } else {
          this.TEL_State = true;
        }
      }
      if(this.cur_vendor.Address1 == '' || this.cur_vendor.City == '' || this.cur_vendor.Province == '' || this.cur_vendor.Country == '' || this.cur_vendor.Postal == '' ){
        this.address_State = false;
        allPassed = false;
      } else {
        this.address_State = true;
      }
      // if(this.cur_vendor_address_array.length < 1){
      //   this.address_State = false;
      //   allPassed = false;
      // } else {
      //   this.address_State = true;
      // }
  
      if(allPassed){
        this.submit_vendor();
      }
    },
    async submit_vendor() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData.append("opt", this.cur_opt);
      if (this.cur_opt == "add") {
        var file_added = "";
        for (var i = 0; i < this.cur_file_list.length; i++) {
          file_added += this.cur_file_list[i].IDn + ",";
        }
        if (file_added != "") {
          bodyFormData.append("file_added", file_added.slice(0, -1));
        }
        bodyFormData.append("new_addresses", JSON.stringify(this.cur_vendor_address_array));
      }
      this.cur_vendor_address_array = [];
      for (var key in this.cur_vendor) {
        bodyFormData.append(key, this.cur_vendor[key]);
      }

      if(this.does_carrier_exist(this.cur_carrier.CarrierName)){
        bodyFormData.append("cur_CarrierIDn", this.cur_carrier.IDn);
      } else {
        this.cur_carrier.CarrierName = document.getElementById("carrier_name_textbox").value;
        bodyFormData.append("cur_CarrierIDn", 0);
        bodyFormData.append("cur_Carrier", JSON.stringify(this.cur_carrier));
      }
      bodyFormData.append("CompanyType", this.cur_CompanyType);
      bodyFormData.append("PayPeriod", this.cur_PayPeriod);
      bodyFormData.append("PriceList", this.cur_PriceList);
      bodyFormData.append("CreditTerm", this.cur_CreditTerm);
      bodyFormData.append("CompanyClass", this.cur_CompanyClass);
      bodyFormData.append("ProductClass", this.cur_ProductClass);
      bodyFormData.append("ItemGrouping", this.cur_ItemGrouping);
      bodyFormData.append("ContactPosition1", this.cur_ContactPosition1);
      bodyFormData.append("ContactPosition2", this.cur_ContactPosition2);
      bodyFormData.append("CustomerType", "Vendor");
      for (var pair of bodyFormData.entries()) {
        // console.log(pair[0]+ '    ' + pair[1]); 
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/Vendor/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vendorlist = rt.data.vendors;
              that.currpage = "list";
              that.cur_opt = "list";
              that.company_typelist = rt.data.company_types;
              that.positionlist = rt.data.positions;
              that.company_classlist = rt.data.company_classes;
              that.product_classlist = rt.data.product_classes;
              that.item_groupinglist = rt.data.item_groupings;
              that.pay_periodlist = rt.data.pay_periods;
              that.credit_termlist = rt.data.credit_terms;
              that.update_carrier_list();
              that.get_vendorMA_list_delay();
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    cancel_edit() {
      this.currpage = "list";
    },
    start_new_address(event) {
      if (event) {
        event.preventDefault();
      }
      // console.log("this.cur_address['ZIP']", this.cur_address['ZIP']);
      for (let key in this.new_address) {
        this.cur_address[key] = this.new_address[key];
      }
      // console.log("this.cur_address['ZIP']", this.cur_address['ZIP']);
    },
    addreess_row_clicked_event(row, event, column) {
      // console.log("addreess_row_clicked_event", row, event, column);
    },
    get_vendorMA_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_vendorMA_list();
      }, 100);
    },
    async get_vendorMA_list() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_CompanyName) {
        bodyFormData.append("CompanyName", this.cur_CompanyName);
      }
      if (this.cur_CompanyName2) {
        bodyFormData.append("OtherName", this.cur_CompanyName2);
      }
      if (this.show_CompanyType) {
        bodyFormData.append("CompanyTypeIDn", this.show_CompanyType);
      }
      if (this.cur_Contact) {
        bodyFormData.append("Name", this.cur_Contact);
      }
      if (this.cur_TEL) {
        bodyFormData.append("TEL", this.cur_TEL);
      }
      if (this.cur_ContactCell1) {
        bodyFormData.append("ContactCell1", this.cur_ContactCell1);
      }
      if (this.cur_ContactEMail1) {
        bodyFormData.append("ContactEMail1", this.cur_ContactEMail1);
      }
      if (this.cur_SalesName) {
        bodyFormData.append("SalesName", this.cur_SalesName);
      }
      if (this.cur_Status) {
        bodyFormData.append("Status", this.cur_Status);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/Vendor",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vendorlist = rt.data.vendors;
              that.vendor_total = rt.data.vendor_total;
              that.company_typelist = rt.data.company_types;
              that.modifyCompanyTypeList();
              that.positionlist = rt.data.positions;
              that.company_classlist = rt.data.company_classes;
              that.product_classlist = rt.data.product_classes;
              that.item_groupinglist = rt.data.item_groupings;
              that.pay_periodlist = rt.data.pay_periods;
              that.credit_termlist = rt.data.credit_terms;
              that.saleslist = rt.data.sales;
              that.modifySaleslist();
              that.carrier_list = rt.data.carriers;
              that.carrier_name_list = rt.data.carrier_names;
              that.carrier_account_no_list = rt.data.carrier_account_nos;
              that.countrylist = rt.data.countries;
              that.provincelist = rt.data.provinces;
              that.citylist = rt.data.cities;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    modifySaleslist() {
      if(this.saleslist) {
        this.saleslist[0] = {"IDn": 0, "UserName": " "};
        Object.entries(this.saleslist).forEach(entry => {
          const [key, value] = entry;
          value.IDn = Number(value.IDn);
        });
      }
    },
    modifyCompanyTypeList() {
      if(this.company_typelist_withEmpty.length == 0) {
        this.company_typelist_withEmpty[0] = {"IDn": 0, "CompanyType": " "};
        Object.entries(this.company_typelist).forEach(entry => {
          const [key, value] = entry;
          this.company_typelist_withEmpty.push({"IDn": Number(value['IDn']), "CompanyType": value['CompanyType']});
        });
      }
    },
    update_carrier_list(){
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/Vendor",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.carrier_list = rt.data.carriers;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async file_upload() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("RefType", "vendor");
      bodyFormData.append("ReferenceIDn", this.cur_vendor.IDn);
      bodyFormData.append("userfile", this.userfile);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/File_upload/upload",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            that.userfile = null;
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_file_list.push(rt.data);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async file_delete(file, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", file.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/File_upload/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            that.userfile = null;
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_file_list = rt.data;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async file_download(file, event) {
      let url = this.extractURL(file.FilePath);
      window.open(url, "_blank");
    },

    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_vendorMA_list();
    },

    format_date(date) {
      // function join(t, a, s) {
      //   function format(m) {
      //     let f = Fnew Intl.DateTimeFormat('en', m);
      //     return f.format(t);
      //   }
      //   return a.map(format).join(s);
      // }
      // let a1 = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      // let s1 = join(date, a1, '-');
      // let a2 = [{hour: '2-digit', hour12: false}, {minute: '2-digit'}, {second: '2-digit'}];
      // let s2 = join(date, a2, ':');
      // return s1 + ' ' + s2;
      let p = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
        .formatToParts(date)
        .reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
        }, {});
      return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    formatDate(timestamp) {
      var utcTime = new Date(timestamp * 1000);
      // const d = new Date();
      // const localOffset = d.getTimezoneOffset() * 60 * 1000;
      // const localTime = utcTime - localOffset;
      return this.format_date(utcTime);
    },
    calculate_el_select_width(){
      for(var i = 0; i < document.getElementsByClassName("el-dropdown").length; i++){
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.borderRadius = "0.375rem";
        document.getElementsByClassName("el-dropdown")[i].style.width = document.getElementsByClassName("el-dropdown")[0].parentElement.clientWidth + "px";
        // document.getElementsByClassName("el-dropdown")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown")[i].style.width) - 10) + 'px'; // -10 because el-row gutter is 10px
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.height = "43px";
      }
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
    setTimeout(()=>{
      this.calculate_el_select_width()
    },50); // timer is used to avoid funciton call before left menu is loaded
    window.addEventListener('resize', this.calculate_el_select_width);
  },
  unmounted(){
    window.removeEventListener('resize', this.calculate_el_select_width);
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.card .table th {
  padding: 0rem;
}
.card .table td {
  padding: 0rem;
}
.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
  
}
/* .el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
} */
.form-group {
  margin-bottom: 0.5rem;
}

.command_button {
  font-weight: 700;
  border-width: 2px;
}

.green_button {
  border-color: green;
  color: green;
}
.green_button:focus {
  border-color: green;
  color: green;
  background-color: #dafada;
}
.green_button:hover {
  border-color: green;
  color: green;
  background-color: #9ef59e;
}
.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #cad1d7;
}
b-card.withoutAddress {
  border: 1px solid red;
}
.dropdown-menu {
  min-width: 2rem !important;
}
</style>
<style lang="scss" scoped>
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #b3e5fc;
}
/deep/ .inactive-vendor {
  background-color: antiquewhite;
}
/deep/ .big-button {
  font-size: 1rem;
  font-weight: 800;
}
/deep/ .table-header-input {
  height: 28px; 
}
</style>
